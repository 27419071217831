// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:35:31+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            2b34b9ba4bc9a4bf7527fa408816dbbd
//    Signature:      eyJpdiI6Im9ZXC9IR3VTeVdRSUZod3lPK2pDb2t3PT0iLCJ2YWx1ZSI6IktEelwvWGpCbkt2Q0owSVVQUVY5N3k1c2ZRUFo4SzZLKzRyWUlGNXVjb2xDMTFjNzhaMEZyNFNtb3NDdkhHUXlZYmxcL05BWUdrV2NaXC9HSUlXZDRJaWh4WVVSQUpZZTUyN2llWnZrTjF4UW5vV0J0bE5BMFFJRkFDSHZHOUtheG1obldzSHoybWZOZVFLQUdKeHd3UE1yTlwvbzhhdStUNTQwc21kbFZCOVdWTXdxRmw0UlZ1Sjhoakg3OHRFbjl3SXZ0V3hlU3l1Z0xIUnZIWFFDZiszMmhWQWUwS1NnU29mYWs0TXVEK2dBT2NabFwva1Q4V0VTQVF0amI5ZVM1QVo5ZkJtZUNSUVVjWFkwS0lQckJlbzNSWFRNWmE3ZGJTSU9YVlQrMlQ5bjJyck9MNFZwSzNjejRlV3Z1TERmcmE3cUpUNzhFNUQ3QW9mZEd0bEg3cUx1ejlBT29WWm84WTg3dUdBVDFPYmNGMWJUQ2t0Z2krY1wvcVluTjNnTFwvNGFyZmlKczVjZGExRUNjWVp0bEEzTDFyQ1ZnPT0iLCJtYWMiOiIzYjIwOTdkMTgxNTFmMDk5OWY3MjMzY2RlNjkyMTQzNDhkZDQ0ZjNlZWRhZjUwMGEyMzIzNmZkNzdmYjE5MmIzIn0=
if (!customElements.get('quick-add-modal')) {
  customElements.define('quick-add-modal', class QuickAddModal extends ModalDialog {
    constructor() {
      super();
      this.modalContent = this.querySelector('[id^="QuickAddInfo-"]');
    }

    hide(preventFocus = false) {
      const cartNotification = document.querySelector('cart-notification');
      if (cartNotification) cartNotification.setActiveElement(this.openedBy);
      this.modalContent.innerHTML = '';
      
      if (preventFocus) this.openedBy = null;
      super.hide();
    }

    show(opener) {
      opener.setAttribute('aria-disabled', true);
      opener.classList.add('loading');
      opener.querySelector('.loading-overlay__spinner').classList.remove('hidden');

      fetch(opener.getAttribute('data-product-url'))
        .then((response) => response.text())
        .then((responseText) => {
          const responseHTML = new DOMParser().parseFromString(responseText, 'text/html');
          this.productElement = responseHTML.querySelector('section[id^="MainProduct-"]');
          this.preventDuplicatedIDs();
          this.removeDOMElements();
          this.setInnerHTML(this.modalContent, this.productElement.innerHTML);
          
          if (window.Shopify && Shopify.PaymentButton) {
            Shopify.PaymentButton.init();
          }

          if (window.ProductModel) window.ProductModel.loadShopifyXR();

          this.removeGalleryListSemantic();
          this.preventVariantURLSwitching();
          super.show(opener);
        })
        .finally(() => {
          opener.removeAttribute('aria-disabled');
          opener.classList.remove('loading');
          opener.querySelector('.loading-overlay__spinner').classList.add('hidden');
        });
    }

    setInnerHTML(element, html) {
      element.innerHTML = html;

      // Reinjects the script tags to allow execution. By default, scripts are disabled when using element.innerHTML.
      element.querySelectorAll('script').forEach(oldScriptTag => {
        const newScriptTag = document.createElement('script');
        Array.from(oldScriptTag.attributes).forEach(attribute => {
          newScriptTag.setAttribute(attribute.name, attribute.value)
        });
        newScriptTag.appendChild(document.createTextNode(oldScriptTag.innerHTML));
        oldScriptTag.parentNode.replaceChild(newScriptTag, oldScriptTag);
      });
    }

    preventVariantURLSwitching() {
      this.modalContent.querySelector('variant-radios,variant-selects').setAttribute('data-update-url', 'false');
    }
    
    removeDOMElements() {
      const pickupAvailability = this.productElement.querySelector('pickup-availability');
      if (pickupAvailability) pickupAvailability.remove();

      const productModal = this.productElement.querySelector('product-modal');
      if (productModal) productModal.remove();
    }

    preventDuplicatedIDs() {
      const sectionId = this.productElement.dataset.section;
      this.productElement.innerHTML = this.productElement.innerHTML.replaceAll(sectionId, `quickadd-${ sectionId }`);
      this.productElement.querySelectorAll('variant-selects, variant-radios').forEach((variantSelect) => {
        variantSelect.dataset.originalSection = sectionId;
      });
    }

    removeGalleryListSemantic() {
      const galleryList = this.modalContent.querySelector('[id^="Slider-Gallery"]');
      if (!galleryList) return;

      galleryList.setAttribute('role', 'presentation');
      galleryList.querySelectorAll('[id^="Slide-"]').forEach(li => li.setAttribute('role', 'presentation'));
    }
  });
}
